import Header from './Header'
import { Container } from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import { createContext, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import useStorage from "../hooks/useStorage";
//import { onMessageListener } from '../firebase';
//import { NewBid } from './CanBid';



export const NavContext = createContext(false);
export const WsContext = createContext(false);


export default function Layout({ setToken }) {

    const [favs, setFavs] = useState(false)
    const [show, setShow] = useState(true)
    const [storage,] = useStorage("session")
    const [toast, setToast] = useState(false)


    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        process.env.REACT_APP_WS, {
        share: !true,
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 100,
        reconnectInterval: 10000,

        queryParams: { uid: storage?.uid }
    })




    /* onMessageListener().then((payload) => {
        setToast(true)
        //console.log("Online", payload)
        setTimeout(() => setToast(false), 10000)
    }) */

    return (
        <NavContext.Provider value={{ favs, setFavs, show, setShow }}>
            <Header setToken={setToken} />
            <WsContext.Provider value={{ sendJsonMessage, lastJsonMessage, readyState }}>
                <Container>
                    <>
                        {/* <NewBid show={toast} /> */}
                        <Outlet />
                    </>
                </Container>
            </WsContext.Provider>
        </NavContext.Provider>
    )
}