import { useNavigate } from "react-router-dom"
import useToken from "../hooks/useToken"


const ApiClient = () => {

    const navigate = useNavigate()
    const { token, setToken } = useToken()
    const baseUrl = process.env.REACT_APP_API_URL

    const Get = async path => {
        const reqOptions = {
            headers: Headers(),
        }
        const res = await fetch(`${baseUrl}${path}`, reqOptions)
        if (res.status === 401) {
            setToken()
            navigate("/auth", { replace: true, redirect: true })
            return
        }
        return await res.json()
    }

    const Post = async (path, body, headers) => {
        let h = headers || {}
        const reqOptions = {
            method: 'POST',
            headers: { ...Headers(), ...h },
            body: JSON.stringify(body)
        }
        const res = await fetch(`${baseUrl}${path}`, reqOptions)
        return await res.json()
    }

    const Put = async (path, id, body) => {
        const reqOptions = {
            method: 'PUT',
            headers: Headers(),
            body: JSON.stringify(body)
        }
        const res = await fetch(`${baseUrl}${path}/${id}`, reqOptions)
        return await res.json()
    }

    const GetStorage = async item => {


        const time = new Date(item.updated_at || item.update_at).getTime()
        const key = `${item.plate}${time}`

        const local = await GetLocalStorage(key)
        if (local != null) {
            return JSON.parse(local)
        }

        const url = `https://storage.googleapis.com/auctions_mmx/${item.plate}/features.json?c=${time}`
        try {
            const res = await fetch(url)
            const body = await res.json()

            SetLocalStorage(key, body)

            return body
        } catch (e) {
            return null
        }
    }

    const GetLocalStorage = async key => {
        return new Promise(resolve => resolve(sessionStorage.getItem(key)))
    }

    const SetLocalStorage = (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value))
    }

    const Headers = () => {
        let httpHeaders = {
            "Content-Type": "application/json"
        }
        if (token) httpHeaders["Authorization"] = token

        return httpHeaders
    }


    return {
        Get,
        Post,
        Put,
        GetStorage
    }
}

export default ApiClient