import { Suspense, lazy } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { Loading } from "./components/Common";
import Layout from './components/Layout'
import useToken from "./hooks/useToken";

//const Home = lazy(() => import('./pages/Home'));
const Item = lazy(() => import("./pages/Item"));
const Login = lazy(() => import("./pages/Login"));
const User = lazy(() => import("./pages/admin/User"))
const UserCreate = lazy(() => import("./pages/admin/UserCreate"))

const List = lazy(() => import("./pages/List"))
const Batch = lazy(() => import("./pages/Batch"))
const Auctions = lazy(() => import("./pages/admin/Auctions"))
const AuctionItems = lazy(() => import("./pages/admin/AuctionItems"))
const ItemMedia = lazy(() => import("./pages/admin/ItemMedia"))
const Signup = lazy(() => import("./pages/Signup"))
const Verify = lazy(() => import("./pages/Verify"))
const Privacy = lazy(() => import("./pages/Privacy"))
const Purchase = lazy(() => import("./pages/Purchase"))
const PurchaseItem = lazy(() => import("./pages/PurchaseItem"))

export const App = () => {
  const { token, setToken } = useToken()
  const { pathname } = useLocation()

  const SignupPage = () => (
    <Suspense fallback={<Loading />}>
      <Signup />
    </Suspense>)

  const LoginPage = () => (
    <Suspense fallback={<Loading />}>
      <Login setToken={setToken} />
    </Suspense>)

  const VerifyPage = () => (
    <Suspense fallback={<Loading />}>
      <Verify />
    </Suspense>)

  const PrivacyPage = () => (
    <Suspense fallback={<Loading />}>
      <Privacy />
    </Suspense>)

  if (!token) {

    if (pathname === "/privacy") {
      return PrivacyPage()
    }

    if (pathname === "/signup") {
      return SignupPage()
    }
    const rgx = /^\/verify\/(\w+\.?)+$/
    if (rgx.test(pathname)) {
      return VerifyPage()
    }

    return LoginPage()
  }

  return (
    <Routes>
      <Route path="/" element={<Layout setToken={setToken} />}>
        <Route index element={<Suspense fallback={<Loading />}><List /></Suspense>} />
        <Route
          path="auctions/:auction_id/item/:id"
          element={<Suspense fallback={<Loading />}><Item /></Suspense>}
        />

        <Route path="auctions/batch" element={<Suspense fallback={<Loading />}><Batch /></Suspense>} />
        <Route path="auctions" element={<Suspense fallback={<Loading />}><Auctions /></Suspense>} />
        <Route path="auctions/:auction_id/items" element={<Suspense fallback={<Loading />}><AuctionItems /></Suspense>} />
        <Route path="auctions/:auction_id/items/:id/media" element={<Suspense fallback={<Loading />}><ItemMedia /></Suspense>} />


        <Route path="users" element={<Suspense fallback={<Loading />}><User /></Suspense>} />
        <Route path="users/:id/edit" element={<Suspense fallback={<Loading />}><UserCreate /></Suspense>} />
        <Route path="account/purchases" element={<Suspense fallback={<Loading />}><Purchase /></Suspense>} />

        <Route path="account/purchases/:id" element={<Suspense fallback={<Loading />}><PurchaseItem /></Suspense>} />

        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="/auth" element={<LoginPage />} />
    </Routes>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
