/* import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './App.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); */

import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
//import * as serviceWorker from './service-worker'

import "./index.css";
import { App } from "./App";
import './App.scss';


ReactDOM.createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider clientId="422516959872-f27bqu9eepmk44ktkt7mmlaojortacna.apps.googleusercontent.com">
    <React.StrictMode>
      <BrowserRouter forceRefresh>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

//serviceWorkerRegistration.register();


//serviceWorkerRegistration.register();
