import { useContext, useCallback, useEffect, useRef } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavContext } from './Layout';
//import logo from '../logo192.png'
import { FavIcon } from './Common';


import { LinkContainer } from 'react-router-bootstrap';
import { MdOutlinePerson, MdOutlinePowerSettingsNew, MdDirectionsCar } from 'react-icons/md';
import { RiAuctionFill } from "react-icons/ri";
import useStorage from '../hooks/useStorage';
import ApiClient from "../services/ApiClient";



export default function Header({ setToken }) {

  const favKey = "favs"
  const [storage,] = useStorage("session")
  const [, setFav] = useStorage(favKey, [])
  const { favs, setFavs, show } = useContext(NavContext)
  const { Get } = ApiClient()
  const loaded = useRef(false)

  const clickShowFavs = e => setFavs(!favs)
  const isAdm = (storage?.rol || 0) === 1


  const fetchData = useCallback(async () => {
    try {
      const { data } = await Get("/users/favs")
      if (data) {
        setFav(data)
      }
    } catch (e) {

    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loaded.current) {
      fetchData()
      loaded.current = true
    }
  }, [fetchData]);

  return (
    <Navbar expand="lg" className="navbar-dark bg-primary" sticky="top">
      <Container className='px-2'>
        <LinkContainer to="/">
          <Navbar.Brand className='py-0'>
            <img
              src={"https://storage.googleapis.com/static_mmxfront/logo.webp"}
              width="auto"
              height="28"
              className="align-top bg-white border shadow"
              alt="Mortormax Logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Nav className="d-flex flex-row">
          {isAdm && <><Nav.Item className='mx-1 d-flex align-items-center d-none d-sm-flex'>
            <LinkContainer to="/users">
              <Nav.Link className='px-0 d-flex align-items-center' active={false}>
                <MdOutlinePerson className='icon' /><span className="ms-1 small fw-semibold">USUARIOS</span>
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>

            <Nav.Item className='mx-1 d-flex align-items-center d-none d-sm-flex'>
              <LinkContainer to="/auctions">
                <Nav.Link className='px-0 d-flex align-items-center' active={false}>
                  <RiAuctionFill className='icon' /><span className="ms-1 fw-semibold small">SUBASTAS</span>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item></>}

          {show && <Nav.Item className='mx-1 d-flex align-items-center'>
            <Nav.Link className='px-0 d-flex align-items-center' onClick={e => clickShowFavs(e)} active={false}>
              <FavIcon value={favs} className='icon' /><span className="ms-1 fw-semibold small">FAVORITOS</span>
            </Nav.Link>
          </Nav.Item>}
          <Nav.Item className='mx-1 me-1 d-flex align-items-center'>
            <LinkContainer to="/account/purchases">
              <Nav.Link className='px-0 d-flex align-items-center' active={false}>
                <MdDirectionsCar className='icon' /><span className="ms-1 fw-semibold small">COMPRAS</span>
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item className='mx-1 me-1 d-flex align-items-center'>
            <Nav.Link className='px-0 d-flex align-items-center' active={false} onClick={e => setToken()}>
              <MdOutlinePowerSettingsNew className='icon' /><span className="ms-1 fw-semibold small">SALIR</span>
            </Nav.Link>
          </Nav.Item>

        </Nav>
      </Container>
    </Navbar>

  );

}


