import { useState } from 'react'
import useStorage from './useStorage'


const useToken = () => {

    const [storage, setStorage] = useStorage("session")
    const [token, setToken] = useState(storage?.token)

    const saveToken = tk => {
        setStorage(tk)
        setToken(tk)
    }

    return {
        setToken: saveToken,
        token
    }
}

export default useToken
