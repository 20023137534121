
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { GiCarWheel, GiFlatTire } from "react-icons/gi";


const OutlineBadge = ({ variant, icon, value }) => {

    if (!value) return <></>
    return <small style={{ fontSize: ".7rem", alignItems: "center" }}
        className={`d-inline-flex text-truncate px-2 py-1 fw-bold text-${variant}-emphasis bg-${variant}-subtle border border-${variant}-subtle rounded-5`}>{icon}{value}</small>
}

const ExtendedBadge = ({ variant }) => {
    return <small style={{ fontSize: ".7rem", alignItems: "center" }}
        className={`d-inline-flex text-truncate px-2 py-1 fw-bold text-white bg-${variant} border border-${variant} rounded-5`}>
        <span className="live me-1"></span>Extendida
    </small>
}

const FavIcon = ({ value, ...props }) => value ? <MdFavorite {...props} /> : <MdFavoriteBorder {...props} />

const Loading = () => {
    return <AbsoluteWrap>
        <div><GiCarWheel className="text-muted wheel fs-8" /></div>
        <div className="p-1 h5 m-0">Cargando...</div>
        <div className="p-0 small text-muted">Aguarde por favor.</div>
    </AbsoluteWrap>
}

const Error = ({ message }) => {
    return <AbsoluteWrap>
        <div><GiFlatTire className="text-muted fs-8" /></div>
        <div className="p-1 h5 m-0">Oppsss...</div>
        <div className="p-0 small text-muted">{message || "Algo salió mal..."}</div>
    </AbsoluteWrap>
}

const AbsoluteWrap = ({ children }) => {
    return <div className='position-absolute top-0 start-0 bottom-0 end-0'>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            {children}
        </div>
    </div>
}


export { FavIcon, Loading, AbsoluteWrap, OutlineBadge, Error, ExtendedBadge }